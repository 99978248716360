import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Array1DRenderer } from '../Array2DRenderer';
import { useSelector } from 'react-redux';
import styles from './ChartRenderer.module.scss';
import { extractThemeColor } from 'common/config';

class ChartRenderer extends Array1DRenderer {
  renderData() {
    const currentTheme = this.props.theme;
    const { data: [row] } = this.props.data;

    const chartData = {
      labels: row.map(col => `${col.value}`),
      datasets: [{
        backgroundColor: row.map(col => col.patched ? 
          extractThemeColor(styles.colorPatched, currentTheme): 
          col.selected ? extractThemeColor(styles.colorSelected, currentTheme) : 
          extractThemeColor(styles.colorFont), currentTheme),
        data: row.map(col => col.value),
      }],
    };
    return (
      <Bar data={chartData} options={{
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        animation: false,
        legend: false,
        responsive: true,
        maintainAspectRatio: false
      }} />
    );
  }
}

const ChartRendererWithTheme = props => {
  const currentTheme = useSelector(state => state.theme.currentTheme);
  return <ChartRenderer {...props} theme={currentTheme} />;
};

export default ChartRendererWithTheme;