import React from 'react';
import { connect } from 'react-redux';
import { themes } from 'common/config';
import { setTheme } from 'reducers/theme';
import styles from './ThemeSelector.module.scss';
import { classes } from 'common/util';

class ThemeSelector extends React.Component {
  handleThemeChange(theme) {
    const { dispatch } = this.props;
    dispatch(setTheme(theme));
  }

  render() {
    const { className, currentTheme } = this.props;
    
    return (
      <select 
        className={classes(styles.theme_selector, className)}
        onChange={e => this.handleThemeChange(themes[e.target.value])}
        value={themes.findIndex(theme => theme.name === currentTheme?.name)}
      >
        {themes.map((theme, i) => (
          <option key={theme.name} value={i}>
            {theme.name}
          </option>
        ))}
      </select>
    );
  }
}

export default connect(state => ({
  currentTheme: state.theme.currentTheme
}))(ThemeSelector);