import { createAction, handleActions } from 'redux-actions';
import { themes } from 'common/config';

const applyTheme = (theme) => {
  Object.entries(theme.colors).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--${key}`, value);
  });
};

// Apply default theme
applyTheme(themes[0]);

const prefix = 'THEME';
export const setTheme = createAction(`${prefix}/SET_THEME`);

const defaultState = {
  currentTheme: themes[0]
};

export default handleActions({
  [setTheme]: (state, { payload }) => {
    applyTheme(payload);
    return {
      ...state,
      currentTheme: payload
    };
  }
}, defaultState);