import Promise from 'bluebird';
import axios from 'axios';

axios.interceptors.response.use(response => response.data);

const request = (url, process) => {
  const tokens = url.split('/');
  const baseURL = /^https?:\/\//i.test(url) ? '' : '/api';
  return (...args) => {
    const mappedURL = baseURL + tokens.map((token) => token.startsWith(':') ? args.shift() : token).join('/');
    return Promise.resolve(process(mappedURL, args));
  };
};

const AlgorithmApi = {
  getCategories: () => fetch('/assets/algorithms/index.json').then(response => response.json()),
  getAlgorithm: (categoryKey, algorithmKey) => 
    fetch(`/assets/algorithms/${categoryKey}/${algorithmKey}.json`).then(response => response.json()),
};

const TracerApi = {
  md: ({ code }) => Promise.resolve([{
    key: 'markdown',
    method: 'MarkdownTracer',
    args: ['Markdown'],
  }, {
    key: 'markdown',
    method: 'set',
    args: [code],
  }, {
    key: null,
    method: 'setRoot',
    args: ['markdown'],
  }]),
  json: ({ code }) => new Promise(resolve => resolve(JSON.parse(code))),
  js: ({ code }, params, cancelToken) => new Promise((resolve, reject) => {
    const worker = new Worker('/assets/script/js-worker.js');
    if (cancelToken) {
      cancelToken.promise.then(cancel => {
        worker.terminate();
        reject(cancel);
      });
    }
    worker.onmessage = e => {
      worker.terminate();
      resolve(e.data);
    };
    worker.onerror = error => {
      worker.terminate();
      reject(error);
    };
    worker.postMessage(code);
  }),
};

export {
  AlgorithmApi,
  TracerApi,
};
