import { DEFAULT_NEW_CODE_FILE } from "./new-algorithms";
const languages = [{
  name: 'JavaScript',
  ext: 'js',
  mode: 'javascript',
  skeleton: DEFAULT_NEW_CODE_FILE,
}];

const themes = [
  {
    name: 'Classic',
    colors: {
      backgroundColor: '#ffffff', // Changed from #242424 to white
      themeNormal: '#f5f5f5', // Changed to light gray
      themeLight: '#e0e0e0', // Changed to lighter gray
      colorFont: '#333333', // Changed to dark gray for better contrast
      colorShadow: 'rgba(0, 0, 0, 0.1)',
      colorOverlay: 'rgba(0, 0, 0, 0.05)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Midnight',
    colors: {
      backgroundColor: '#1a1a2e',
      themeNormal: '#16213e',
      themeLight: '#1b2430',
      colorFont: '#e2e2e2',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Ocean',
    colors: {
      backgroundColor: '#1b262c',
      themeNormal: '#0f4c75',
      themeLight: '#3282b8',
      colorFont: '#bbe1fa',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Forest',
    colors: {
      backgroundColor: '#2d3436',
      themeNormal: '#354f52',
      themeLight: '#52796f',
      colorFont: '#cad2c5',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Sunset',
    colors: {
      backgroundColor: '#2d132c',
      themeNormal: '#801336',
      themeLight: '#c72c41',
      colorFont: '#ee4540',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Desert',
    colors: {
      backgroundColor: '#3c2f2f',
      themeNormal: '#be9b7b',
      themeLight: '#d4b996',
      colorFont: '#fff4e6',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Arctic',
    colors: {
      backgroundColor: '#2c3e50',
      themeNormal: '#34495e',
      themeLight: '#487eb0',
      colorFont: '#dcdde1',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Volcanic',
    colors: {
      backgroundColor: '#1e272e',
      themeNormal: '#2d3436',
      themeLight: '#485460',
      colorFont: '#ff5252',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Lavender',
    colors: {
      backgroundColor: '#2c2c54',
      themeNormal: '#474787',
      themeLight: '#6c5ce7',
      colorFont: '#e9e9e9',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  },
  {
    name: 'Emerald',
    colors: {
      backgroundColor: '#004d40',
      themeNormal: '#00695c',
      themeLight: '#00897b',
      colorFont: '#e0f2f1',
      colorShadow: 'rgba(0, 0, 0, 0.2)',
      colorOverlay: 'rgba(255, 255, 255, 0.1)',
      colorAlert: '#f3bd58',
      colorSelected: '#2962ff',
      colorPatched: '#c51162',
      colorHighlight: '#29d',
      colorActive: '#00e676'
    }
  }
];

const exts = languages.map(language => language.ext);

const extractThemeColor = (cssVar) => {
  // Remove 'var(' and ')' and any whitespace
  const cleaned = cssVar.trim().replace(/var\(|\)/g, '');
  // Split by comma to separate variable name and fallback
  const [varName, fallback] = cleaned.split(',').map(s => s.trim());
  const themeColor = document.documentElement.style.getPropertyValue(varName);
  // Return theme color if exists, otherwise return fallback or null
  return themeColor || fallback || null;
};

export {
  languages,
  themes,
  exts,
  extractThemeColor,
};