import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AutosizeInput from 'react-input-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import faAngleRight from '@fortawesome/fontawesome-free-solid/faAngleRight';
import faCaretDown from '@fortawesome/fontawesome-free-solid/faCaretDown';
import faCaretRight from '@fortawesome/fontawesome-free-solid/faCaretRight';
import faLanguage from '@fortawesome/fontawesome-free-solid/faLanguage';
import { useTranslation } from 'react-i18next';
import { classes } from 'common/util';
import { actions } from 'reducers';
import Button from '../Button';
import Ellipsis from '../Ellipsis';
import ListItem from '../ListItem';
import Player from '../Player';
import styles from './Header.module.scss';

import ThemeSelector from '../ThemeSelector';

const Header = (props) => {
  const { t, i18n } = useTranslation();

  const handleChangeTitle = (e) => {
    const { value } = e.target;
    props.modifyTitle(value);
  };

  const { className, onClickTitleBar, navigatorOpened } = props;
  const { scratchPaper, titles } = props.current;

  return (
    <header className={classes(styles.header, className)}>
      <div className={styles.row}>
        <div className={styles.section}>
          <Button className={styles.title_bar} onClick={onClickTitleBar}>
            {
              titles.map((title, i) => [
                scratchPaper && i === 1 ?
                  <AutosizeInput className={styles.input_title} key={`title-${i}`} value={title}
                               onClick={e => e.stopPropagation()} onChange={handleChangeTitle}/> :
                  <Ellipsis key={`title-${i}`}>{title}</Ellipsis>,
                i < titles.length - 1 &&
                <FontAwesomeIcon className={styles.nav_arrow} fixedWidth icon={faAngleRight} key={`arrow-${i}`}/>,
              ])
            }
            <FontAwesomeIcon className={styles.nav_caret} fixedWidth
                           icon={navigatorOpened ? faCaretDown : faCaretRight}/>
          </Button>
        </div>
        <Player className={styles.player_section}/>
        <div className={styles.section}>
          <Button className={styles.btn_dropdown} icon={faLanguage}>
            {t(`languages.${i18n.language}`)}
            <div className={styles.dropdown}>
              {Object.keys(i18n.options.resources).map(langCode => (
                <ListItem
                  key={langCode}
                  onClick={() => i18n.changeLanguage(langCode)}
                  label={t(`languages.${langCode}`)}
                />
              ))}
            </div>
          </Button>

          <ThemeSelector className={styles.theme_selector} />
        </div>
      </div>
    </header>
  );
};

export default withRouter(
  connect(({ current, env }) => ({ current, env }), actions)(
    Header,
  ),
);