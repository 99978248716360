export const DEFAULT_NEW_CODE_FILE = {
    "name": "code.js",
    "content": `// import visualization libraries {
const { Array2DTracer, Layout, LogTracer, Tracer, VerticalLayout } = require('algorithm-visualizer');
// }

// define tracer variables {
const array2dTracer = new Array2DTracer('Grid');
const logTracer = new LogTracer('Console');
// }

// define input variables
const messages = [
'Visualize',
'your',
'own',
'code',
'here!',
];

// highlight each line of messages recursively
function highlight(line) {
if (line >= messages.length) return;
const message = messages[line];
// visualize {
logTracer.println(message);
array2dTracer.selectRow(line, 0, message.length - 1);
Tracer.delay();
array2dTracer.deselectRow(line, 0, message.length - 1);
// }
highlight(line + 1);
}

(function main() {
// visualize {
Layout.setRoot(new VerticalLayout([array2dTracer, logTracer]));
array2dTracer.set(messages);
Tracer.delay();
// }
highlight(0);
})();`,
};

export const DEFAULT_NEW_README =  {
  "name": "README.md",
  "content": `# Scratch Paper

Visualize your own code here!

## Learning About Tracers

The project [Algorithm Cool](https://github.com/algorithm-visualizer) has a visualization library in each
supported language ([JavaScript](https://github.com/algorithm-visualizer/tracers.js)
, [C++](https://github.com/algorithm-visualizer/tracers.cpp),
and [Java](https://github.com/algorithm-visualizer/tracers.java)) to visualize codes.

There are five tracers in the library to visualize different types of data:

- [Array1DTracer](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki/Array1DTracer)
- [Array2DTracer](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki/Array2DTracer)
- [ChartTracer](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki/ChartTracer)
- [GraphTracer](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki/GraphTracer)
- [LogTracer](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki/LogTracer)

There are also randomizers to help you create random data.

Check out the [API reference](https://github.com/algorithm-visualizer/algorithm-visualizer/wiki) for more information.`,
};

export const DEFAULT_NEW_ALGORITHMS = {
  "files": [DEFAULT_NEW_README, DEFAULT_NEW_CODE_FILE]
}